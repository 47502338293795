<template>
  <div>
    <h1>GET DATA FROM URL</h1>
    <h3>URL : {{url}}</h3>
    <input type="text" placeholder="api/users" v-model.trim="url" />
    <button type="button" @click="getData">Get Data</button>
    <h5>{{eventMessage}}</h5>
    <div>
      <h2>Data :</h2>
      {{dataLists}}
    </div>
    <div>
      <h2>Pagination :</h2>
      {{pagination}}
    </div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      url: "",
    };
  },
  computed: {
    ...mapGetters(["dataLists", "pagination", "eventMessage"]),
  },
  methods: {
    getData() {
      this.$store.commit("getData", this.url);
    },
  },
};
</script>
